.list-prev-container {
    flex-wrap: wrap;
    display: flex;
    flex: row;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
}

.list-prev-card {
    padding: 0;
    border-radius: 10px;
    border-style: solid;
    flex: 1;
    margin: 30px;
    min-width: 280px;
    max-width: 16rem;
    text-align: center;
}

.list-prev-image {
    background-color: "white";
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    height: 180px;
    object-fit: contain;
}

.list-prev-msrp {
    font-size: .8em;
}

.list-prev-c {
    font-size: .8em;
}

.list-prev-c-badge {
    padding-left: 5px;
    padding: 10px; 
    background-color: #13195f;
    border-radius: 10px;
    color: white;
}

.list-prev-bidder {
    font-size: .8em;
}

.list-prev-title {
    padding-top: 10px; 
    font-size: 0/9em; 
    cursor: pointer;
}