.btn-flat {
    background-color: purple;
    color: white;
  }

.btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
}

.btn-other-compact {
  background-color: #13195f !important;
  color: white;
  border-radius: 10px;
  font-size: 0.8em;
}

.btn-other-sm {
  background-color: #13195f !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}

.btn-other-sm-tag {
  background-color: #13195f;
  color: white;
  border-radius: 10px;
  font-size: 0.8em;
  padding: 10px;
}

.btn-other {
  background-color: #13195f !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}


.btn-other:hover {
  background-color: #33397f !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}

.btn-other:focus {
  background-color: #33397f !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}

.btn-focused {
  background-color: #1a63a7 !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}

.btn-focused:hover {
  background-color: #57a6ce !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}

.btn-focused:focus {
  background-color: #57a6ce !important;
  color: white;
  border-radius: 10px;
  font-size: 1.2em;
}

.btn-fill-96 {
  height: 96px;
}

.btn-xxl {
padding: 1rem 1.5rem;
font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .btn-other-sm {
    font-size: 0.8em;
    padding: 10px;
  }
}


