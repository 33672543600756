.countdown-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0.5rem
  }
  
  .countdown-container > form {
    padding: 0.5rem;
  }
  
  .countdown-container > form > label {
    margin-right: 1rem;
  }
  
  .expired-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 2rem;
    padding: 1rem;
    margin-top: 2px;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    background-color: #ececec;
    border: 1px solid #ebebeb;
    border-radius: .75rem;
    text-decoration: none;
  }
  
  .expired-notice > span {
    font-size: 1.2rem;
    font-weight: bold;
    color: #13195f;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    padding: 0rem;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 2rem;
    padding: 1rem;
    border: 1px solid #ebebeb;
    border-radius: .75rem;
    text-decoration: none;
    color: #000;
    background-color: #ececec;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0 0 0;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
    flex: 1;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 2rem;
    flex: none;

  }
  
  @media only screen and (max-width: 500px) {
    .show-counter .countdown > span {
      font-size: 1rem;
      line-height: 1rem;
    }
  
    .show-counter .countdown-link { 
      font-size: 1.5rem;
    }
  }